import { generatePhonesQueryString, generateQueryString, getLinesFromString } from './utils/QueryStringHelper';


(function () {
    'use strict';

    WhistleOut.MobilePhones = {};
    WhistleOut.MobilePhones.generateQueryString = generateQueryString;
    WhistleOut.MobilePhones.generatePhonesQueryString = generatePhonesQueryString;

    WhistleOut.MobilePhones.bindByoPopover =
        function (sourceElement, content, optionSelectedCallback, placementOverride, selectedByoPhoneModelId) {

            if (content && content.length > 0) {

                if (!placementOverride) {
                    placementOverride = 'top';
                }

                wo$('body').off('click', WhistleOut.MobilePhones.hideByoPopover).click(WhistleOut.MobilePhones.hideByoPopover);

                wo$(sourceElement).off('click').on('click',
                    function () {
                        wo$(sourceElement).off('shown.bs.popover').on('shown.bs.popover',
                            function () {
                                var popoverContainer = wo$('[data-byod-popover]');

                                var brandButtonText = popoverContainer.find('span[data-brand-button-text]');
                                var phoneButton = popoverContainer.find('button[data-phone-button]');
                                var phoneButtonText = popoverContainer.find('span[data-phone-button-text]');
                                var modelButton = popoverContainer.find('button[data-model-button]');
                                var modelButtonText = popoverContainer.find('span[data-model-button-text]');
                                var genericByoButton = popoverContainer.find('[data-generic-byo]');

                                var brandOptions = popoverContainer.find('a[data-brand]');
                                var phoneOptions = popoverContainer.find('a[data-phone]');
                                var modelOptions = popoverContainer.find('a[data-model]');

                                var brandSelected = function (selectedOption) {
                                    phoneOptions.hide();
                                    modelOptions.hide();
                                    modelButton.prop('disabled', true);

                                    brandButtonText.text(selectedOption.text());
                                    phoneButtonText.text(phoneButtonText.data('phone-button-text'));
                                    modelButtonText.text(modelButtonText.data('model-button-text'));

                                    var brandShortUrl = selectedOption.data('brand');
                                    if (brandShortUrl && brandShortUrl.length) {
                                        phoneOptions.each(function () {
                                            var option = wo$(this);
                                            if (option.data('parent-brand') === brandShortUrl) {
                                                option.show();
                                            }
                                        });
                                        phoneButton.prop('disabled', false);
                                    } else {
                                        phoneButton.prop('disabled', true);
                                    }
                                };

                                var phoneSelected = function (selectedOption) {
                                    modelOptions.hide();

                                    phoneButtonText.text(selectedOption.text());
                                    modelButtonText.text(modelButtonText.data('model-button-text'));

                                    var phoneShortUrl = selectedOption.data('phone');
                                    if (phoneShortUrl && phoneShortUrl.length) {
                                        modelOptions.each(function () {
                                            var option = wo$(this);
                                            if (option.data('parent-phone') === phoneShortUrl) {
                                                option.show();
                                            }
                                        });
                                        modelButton.prop('disabled', false);
                                    } else {
                                        modelButton.prop('disabled', true);
                                    }
                                };

                                var modelSelected = function (selectedOption) {
                                    modelButtonText.text(selectedOption.text());
                                };

                                if (selectedByoPhoneModelId) {
                                    var modelOption = popoverContainer.find('a[data-model="' + selectedByoPhoneModelId + '"]');
                                    var modelDetails = modelOption.data('model-details');

                                    var brandOption = popoverContainer.find('a[data-brand="' + modelDetails.manufacturerShortUrl + '"]');
                                    brandSelected(brandOption);

                                    var phoneOption = popoverContainer.find('a[data-phone="' + modelDetails.shortUrl + '"]');
                                    phoneSelected(phoneOption);

                                    modelSelected(modelOption);
                                }

                                brandOptions.off('click').on('click', function () {
                                    brandSelected(wo$(this));
                                });
                                phoneOptions.off('click').on('click', function () {
                                    phoneSelected(wo$(this));
                                });
                                modelOptions.off('click').on('click', function () {
                                    modelSelected(wo$(this));

                                    var modelDetails = wo$(this).data('model-details');
                                    if (modelDetails) {
                                        wo$(sourceElement).popover('destroy');
                                        optionSelectedCallback(modelDetails);
                                    }
                                });
                                genericByoButton.off('click').on('click', function () {
                                    wo$(sourceElement).popover('destroy');
                                    optionSelectedCallback(null);
                                });
                            });

                        if (wo$(sourceElement).data('bs.popover')) {
                            wo$(sourceElement).popover('toggle');
                        } else {
                            wo$(sourceElement).popover({
                                container: 'body',
                                placement: placementOverride,
                                trigger: 'manual',
                                html: true,
                                content: content
                            });
                            wo$(sourceElement).popover('show');
                        }

                    });
            } else {
                wo$(sourceElement).click(function () {
                    optionSelectedCallback(null, null);
                });
            }
        };

    WhistleOut.MobilePhones.hideByoPopover = function (e) {
        wo$('[data-show-byo-button]').each(function () {
            var $this = wo$(this);
            if (!$this.is(e.target) && $this.has(e.target).length === 0 && wo$('.popover').has(e.target).length === 0) {
                $this.popover('destroy');
            }
        });
    };

    WhistleOut.MobilePhones.bindPhonePickerPopover = function (sourceElement, content, phoneSelected) {
        wo$('body').off('click', WhistleOut.MobilePhones.hidePhonePickerPopover)
            .click(WhistleOut.MobilePhones.hidePhonePickerPopover);
        wo$(sourceElement).off('click').on('click',
            function () {
                wo$(sourceElement).off('shown.bs.popover').on('shown.bs.popover',
                    function () {
                        wo$('[data-phone-picker-button]').click(function () {
                            wo$(sourceElement).popover('destroy');
                            WhistleOut.MobilePhones.showPhonePicker(false, phoneSelected);
                        });
                        wo$('[data-phone-picker-wizard-button]').click(function () {
                            wo$(sourceElement).popover('destroy');
                            WhistleOut.MobilePhones.showPhonePicker(true, phoneSelected);
                        });
                    });

                if (wo$(sourceElement).data('bs.popover')) {
                    wo$(sourceElement).popover('toggle');
                } else {
                    wo$(sourceElement).popover({
                        container: 'body',
                        placement: 'top',
                        trigger: 'manual',
                        html: true,
                        content: content
                    });
                    wo$(sourceElement).popover('show');
                }

            });
    };

    WhistleOut.MobilePhones.hidePhonePickerPopover = function (e) {
        wo$('[data-show-phone-modal]').each(function () {
            var $this = wo$(this);
            if (!$this.is(e.target) && $this.has(e.target).length === 0 && wo$('.popover').has(e.target).length === 0) {
                $this.popover('destroy');
            }
        });
    };

    WhistleOut.MobilePhones.hideByodPopover = function (e) {
        wo$('[data-show-byo-phone-modal-selector]').each(function () {
            var $this = wo$(this);
            if (!$this.is(e.target) && $this.has(e.target).length === 0 && wo$('.popover').has(e.target).length === 0) {
                $this.popover('destroy');
            }
        });
    };

    WhistleOut.MobilePhones.showPhonePicker = function (wizardMode, phoneSelected, byoPhoneModelId) {
        WhistleOut.phonePickerController.show(wizardMode, phoneSelected, byoPhoneModelId);
    };

    WhistleOut.MobilePhones.bindCriteriaFilters = function (elements, callback, criteria) {
        var setLineProps = function (criteria, key, val) {
            if (!criteria.lines || criteria.lines.length === 0) {
                criteria.lines = [{}];
            }

            criteria.lines[0][key] = val;
        };

        var trueOrNull = WhistleOut.trueOrNull;
        var falseOrNull = WhistleOut.falseOrNull;
        var boolOrNull = WhistleOut.boolOrNull;

        const mappings = {
            '5g': function (e, val) {
                e.show5G = trueOrNull(val);
            },
            esim: function (e, val) {
                e.showESim = trueOrNull(val);
            },
            address: function (e, val) {
                e.address = val;
            },
            bluetick: function (e, val) {
                e.showTelstraBlueTickOnly = trueOrNull(val);
            },
            brand: function (e, val) {
                e.brands = (val || '').split(',');
            },
            calls: function (e, val) {
                setLineProps(e, 'calls', val);
            },
            camera: function (e, val) {
                e.camera = Number(val);
            },
            campaign: function (e, val) {
                e.campaign = val;
            },
            campaigngroup: function (e, val) {
                e.campaigngroup = val;
            },
            currentsupplier: function (e, val) {
                e.currentsupplier = val;
            },
            customer: function (e, val) {
                e.customer = val;
            },
            data: function (e, val) {
                e.minData = Number(val);
                setLineProps(e, 'data', val);
            },
            dealsonly: function (e, val) {
                e.dealsonly = trueOrNull(val);
            },
            direct: function (e, val) {
                e.directClick = val;
            },
            eligibility: function (e, val) {
                e.eligibility = (val || '').split(',');
            },
            editorspicks: function (e, val) {
                e.showEditorsPicksOnly = trueOrNull(val);
            },
            enforcemaximumresultlimits: function (e, val) {
                e.enforcemaximumresultlimits = falseOrNull(val);
            },
            expressmode: function (e, val) {
                e.expressmode = trueOrNull(val);
            },
            firstresponders: function (e, val) {
                e.includeFirstResponders = boolOrNull(val);
            },
            fullspeeddata: function (e, val) {
                e.fullSpeedData = falseOrNull(val);
            },
            hidecoverage: function (e, val) {
                e.hidecoverage = trueOrNull(val);
            },
            hidecoveragestrip: function (e, val) {
                e.hideCoverageStrip = trueOrNull(val);
            },
            hideshare: function (e, val) {
                e.hideShare = trueOrNull(val);
            },
            hidewidenresults: function (e, val) {
                e.hideWidenResults = trueOrNull(val);
            },
            includefeatured: function (e, val) {
                e.includefeatured = falseOrNull(val);
            },
            internationalcalls: function (e, val) {
                e.includeInternationalCalls = trueOrNull(val);
            },
            lines: function (e, val) {
                e.lines = getLinesFromString(val);
            },
            maxprepaidexpiry: function (e, val) {
                e.maximumPrepaidExpiry = Number(val);
            },
            maxresults: function (e, val) {
                e.maxresults = Number(val);
            },
            maxscreen: function (e, val) {
                e.maxScreen = Number(val);
            },
            maxspend: function (e, val) {
                e.maxSpend = Number(val);
            },
            maxupfront: function (e, val) {
                e.maxSpend = Number(val);
            },
            military: function (e, val) {
                e.includeMilitary = boolOrNull(val);
            },
            minscreen: function (e, val) {
                e.minScreen = Number(val);
            },
            minspend: function (e, val) {
                e.minSpend = Number(val);
            },
            minutes: function (e, val) {
                setLineProps(e, 'minutes', val);
            },
            monetised: function (e, val) {
                e.monetisedResultsOnly = trueOrNull(val);
            },
            network: function (e, val) {
                e.network = val;
                e.networks = (val || '').split(',');
            },
            optuspick: function (e, val) {
                e.optusRegionalPickOnly = trueOrNull(val);
            },
            os: function (e, val) {
                e.operatingSystems = (val || '').split(',');
            },
            pagesize: function (e, val) {
                e.pagesize = Number(val);
            },
            phone: function (e, val) {
                e.models = (val || '').split(',');
                setLineProps(e, 'phone', val);
            },
            phonesub: function (e, val) {
                setLineProps(e, 'allowPhoneSub', trueOrNull(val));
            },
            prepaid: function (e, val) {
                e.prepaid = Number(val);
            },
            prepaidexpiry: function (e, val) {
                e.prepaidExpiry = Number(val);
            },
            pricetype: function (e, val) {
                e.priceTypes = (val || '').split(',');
            },
            product: function (e, val) {
                e.products = (val || '').split(',');
            },
            productgroup: function (e, val) {
                e.productGroups = (val || '').split(',');
            },
            productlabel: function (e, val) {
                e.productlabel = decodeURIComponent(val);
            },
            refurb: function (e, val) {
                e.refurbishedPhones = val;
            },
            released: function (e, val) {
                e.released = Number(val);
            },
            reseller: function (e, val) {
                e.reseller = val;
            },
            search: function (e, val) {
                e.search = val;
            },
            seniors: function (e, val) {
                e.includeSeniors = boolOrNull(val);
            },
            showall: function (e, val) {
                e.showall = trueOrNull(val);
            },
            showrowfilters: function (e, val) {
                e.showRowFilters = falseOrNull(val);
            },
            simonly: function (e, val) {
                var simonly =
                    trueOrNull(val) &&
                    !(e.lines && e.lines[0] && e.lines[0].phone);

                setLineProps(e, 'simOnly', simonly);
            },
            simtype: function (e, val) {
                setLineProps(e, 'simType', Number(val));
            },
            singlePhonePerBrand: function (e, val) {
                e.singlePhonePerBrand = trueOrNull(val);
            },
            sms: function (e, val) {
                setLineProps(e, 'sms', val);
            },
            sort: function (e, val) {
                e.sortExpression = val;
            },
            sortascending: function (e, val) {
                e.sortAscending = falseOrNull(val);
            },
            spend: function (e, val) {
                e.maxSpend = Number(val);
            },
            state: function (e, val) {
                e.state = val;
            },
            supplier: function (e, val) {
                e.suppliers = (val || '').split(',');
            },
            tab: function (e, val) {
                e.tab = val;
            },
            tetherPhone: function (e, val) {
                setLineProps(e, 'tether', trueOrNull(val));
            },
            type: function (e, val) {
                e.planType = val;
                e.planTypes = (val || '').split(',');
            },
        };

        WhistleOut.bindCriteriaFilters(elements, callback, criteria, mappings);
    };
}());