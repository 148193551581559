
// TODO: Try reducing cyclomatic complexity and enable the eslint rule
// eslint-disable-next-line complexity
export function generateQueryString(data) {
    var config = WhistleOut.getSiteConfiguration();
    console.log({HC:config.mobilePhonesConfig.hasCoverage});
    console.log({state:data.state});
    if (data.lines && data.lines.length > 0) {
        var linesQueryStrings = [];
        var queryString = '';

        if (data.lines.length > 1) {
            linesQueryStrings = _getLinesQueryString(data.lines);
        } else if (data.lines && data.lines.length === 1) {

            var lineValues = data.lines[0];
            if (lineValues.phone)
                queryString = WhistleOut.appendQueryParam(queryString, 'phone=' + lineValues.phone);
            if (lineValues.calls)
                queryString = WhistleOut.appendQueryParam(queryString, 'calls=' + lineValues.calls);
            if (lineValues.minutes)
                queryString = WhistleOut.appendQueryParam(queryString, 'minutes=' + lineValues.minutes);
            if (lineValues.sms)
                queryString = WhistleOut.appendQueryParam(queryString, 'sms=' + lineValues.sms);
            if (lineValues.data)
                queryString = WhistleOut.appendQueryParam(queryString, 'data=' + lineValues.data);
            if (lineValues.allowPhoneSub === 1 || lineValues.allowPhoneSub === true)
                queryString = WhistleOut.appendQueryParam(queryString, 'phonesub=true');
            if (lineValues.tetherPhone === 1 || lineValues.tetherPhone === true)
                queryString = WhistleOut.appendQueryParam(queryString, 'tether=true');
            if (lineValues.maximumdata) {
                queryString = WhistleOut.appendQueryParam(queryString, 'maximumdata=' + lineValues.maximumdata);
            }

            if (lineValues.phonePriceTypes &&
                lineValues.phonePriceTypes.length > 0 &&
                lineValues.phonePriceTypes.length !== config.mobilePhonesConfig.phonePriceTypeCount)
                queryString = WhistleOut.appendQueryParam(queryString,
                    'phoneprice=' +
                    lineValues.phonePriceTypes.map(function (priceType) { return priceType.value; }).join(','));
            if (lineValues.simOnly === true && !lineValues.phone) {
                queryString = WhistleOut.appendQueryParam(queryString, 'simonly=true');
                if (lineValues.byoPhoneModelId && lineValues.byoPhoneModelId > 0)
                    queryString = WhistleOut.appendQueryParam(queryString, 'byod=' + lineValues.byoPhoneModelId);
            }
        }

        if (data.customer && data.customer.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'customer=' + data.customer);
        }

        if (parseInt(data.maxupfront) > -1) {
            queryString = WhistleOut.appendQueryParam(queryString, 'maxupfront=' + data.maxupfront);
        }

        if (data.campaign && data.campaign.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'campaign=' + data.campaign);
        }

        if (data.campaignGroup && data.campaignGroup.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'campaigngroup=' + data.campaignGroup);
        }

        if (data.dealsonly === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'dealsonly=true');
        }

        if (data.monetisedResultsOnly === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'monetised=true');
        }

        if (data.minSpend && WhistleOut.isPositiveNumber(data.minSpend)) {
            queryString = WhistleOut.appendQueryParam(queryString, 'minspend=' + data.minSpend);
        }

        if (data.maxSpend &&
            WhistleOut.isPositiveNumber(data.maxSpend)) {
            queryString = WhistleOut.appendQueryParam(queryString, 'spend=' + data.maxSpend);
        }

        if (data.suppliers && data.suppliers.length > 0) {
            if (data.allSuppliers) {
                if (data.suppliers.length !== data.allSuppliers.length) {
                    queryString = WhistleOut.appendQueryParam(queryString, 'supplier=' + data.suppliers.join(','));
                }
            } else {
                //ToDo: Remove when refactoring modal
                var supplierUrls = data.suppliers.filter(function (supplier) { return supplier.isChecked; })
                    .map(function (supplier) { return supplier.shortUrl; });
                if (supplierUrls && supplierUrls.length > 0 && data.suppliers.length !== supplierUrls.length) {
                    queryString = WhistleOut.appendQueryParam(queryString, 'supplier=' + supplierUrls.join(','));
                }
            }
        }

        if (data.reseller) {
            queryString = WhistleOut.appendQueryParam(queryString, 'reseller=' + data.reseller);
        }

        if (data.maxResultsPerSupplier > 1) {
            queryString =
                WhistleOut.appendQueryParam(queryString, 'maxresultspersupplier=' + data.maxResultsPerSupplier);
        } else if (data.singleResultPerSupplier === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'maxresultspersupplier=1');
        }

        if (data.hideEmptyTabs === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'hideEmptyTabs=true');
        }

        if (data.fullSpeedData === false) {
            queryString = WhistleOut.appendQueryParam(queryString, 'fullspeeddata=false');
        }

        if (data.speed) {
            queryString = WhistleOut.appendQueryParam(queryString, 'speed=' + data.speed);
        }

        if (data.maxSpeed) {
            queryString = WhistleOut.appendQueryParam(queryString, 'maxspeed=' + data.maxSpeed);
        }

        if (data.speedExact === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'speedexact=true');
        }

        if (data.currentsupplier) {
            queryString = WhistleOut.appendQueryParam(queryString, 'currentsupplier=' + data.currentsupplier);
        }

        if (data.prepaidExpiry && data.prepaidExpiry !== 0 && data.prepaidExpiry !== '0') {
            queryString = WhistleOut.appendQueryParam(queryString, 'prepaidexpiry=' + data.prepaidExpiry);
        }

        if (data.maximumPrepaidExpiry && data.maximumPrepaidExpiry !== 0 && data.maximumPrepaidExpiry !== '0') {
            queryString = WhistleOut.appendQueryParam(queryString, 'maxprepaidexpiry=' + data.maximumPrepaidExpiry);
        }

        if (linesQueryStrings.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'lines=' + linesQueryStrings.join(','));
        }

        if (data.showAll && data.showAll === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'showall=true');
        }

        if (data.network && data.network.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'network=' + data.network);
        }

        if (data.sortExpression && data.sortExpression !== 'Price') {
            queryString = WhistleOut.appendQueryParam(queryString, 'sort=' + data.sortExpression);
        }

        if (data.sortAscending === false) {
            queryString = WhistleOut.appendQueryParam(queryString, 'sortascending=false');
        }

        if (data.includefeatured === false) {
            queryString = WhistleOut.appendQueryParam(queryString, 'includefeatured=false');
        }

        if (data.enforcemaximumresultlimits === false) {
            queryString = WhistleOut.appendQueryParam(queryString, 'enforcemaximumresultlimits=false');
        }

        // TODO: Fix the violation and enable the rule
        // eslint-disable-next-line eqeqeq
        if (data.state) {
            queryString = WhistleOut.appendQueryParam(queryString, 'state=' + data.state);
        }

        if (data.showRowFilters === false) {
            queryString = WhistleOut.appendQueryParam(queryString, 'showrowfilters=false');
        }

        if (data.hideWidenResults && data.hideWidenResults === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'hidewidenresults=true');
        }

        if (data.hideShare && data.hideShare === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'hideshare=true');
        }

        if (config.mobilePhonesConfig.hasCoverage === true && data.hideCoverage) {
            queryString = WhistleOut.appendQueryParam(queryString, 'hidecoverage=true');
        }

        if (data.hideCoverageStrip && data.hideCoverageStrip === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'hidecoveragestrip=true');
        }

        if (data.maxResults && data.maxResults > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'maxresults=' + data.maxResults);
        }

        if (data.pageSize && data.pageSize > 0 && data.pageSize !== data.defaultPageSizeConstant) {
            queryString = WhistleOut.appendQueryParam(queryString, 'pagesize=' + data.pageSize);
        }

        if (data.expressmode && data.expressmode === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'expressmode=true');
        }

        if (data.planTypes && data.planTypes.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'type=' + data.planTypes.join(','));
        }

        if (data.address && data.address.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'address=' + data.address.replace(/\s+/g, '+'));
        }

        if (data.includeInternationalCalls && data.includeInternationalCalls === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'internationalcalls=true');
        }

        if (data.includeFiveG && data.includeFiveG === true) {
            queryString = WhistleOut.appendQueryParam(queryString, '5g=true');
        }

        if (data.directClick) {
            queryString = WhistleOut.appendQueryParam(queryString, 'direct=' + data.directClick);
        }

        if (data.includeSeniors === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'seniors=true');
        } else if (data.includeSeniors === false)
            queryString = WhistleOut.appendQueryParam(queryString, 'seniors=false');

        if (data.eligibility && data.eligibility.length > 0) {
            var allEligibility = config.mobilePhonesConfig.eligibility.toString().split(',');
            var dataEligibility = data.eligibility.split(',');
            var validEligibility = dataEligibility.filter(function (x) {
                return allEligibility.indexOf(x) > -1;
            }).sort().join(',');
            validEligibility = dataEligibility.indexOf('none') > -1 && validEligibility.length > 0 ? 'none,' + validEligibility : validEligibility;

            if(validEligibility)
                queryString = WhistleOut.appendQueryParam(queryString, 'eligibility=' + validEligibility);
        }

        if (data.includeMilitary === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'military=true');
        } else if (data.includeMilitary === false)
            queryString = WhistleOut.appendQueryParam(queryString, 'military=false');

        if (data.includeFirstResponders === true) {
            queryString = WhistleOut.appendQueryParam(queryString, 'firstresponders=true');
        } else if (data.includeFirstResponders === false)
            queryString = WhistleOut.appendQueryParam(queryString, 'firstresponders=false');

        if (data.tab && data.tab !== config.mobilePhonesConfig.defaultTab) {
            queryString = WhistleOut.appendQueryParam(queryString, 'tab=' + data.tab);
        }

        if (data.prepaid && data.prepaid!==1) {
            queryString = WhistleOut.appendQueryParam(queryString, 'prepaid=' + data.prepaid);
        }

        if (data.productLabel) {
            queryString =
                WhistleOut.appendQueryParam(queryString, 'productlabel=' + encodeURIComponent(data.productLabel));
        }

        if (data.products && data.products.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'product=' + data.products.join(','));
        }

        if (data.productGroups && data.productGroups.length > 0) {
            queryString = WhistleOut.appendQueryParam(queryString, 'productgroup=' + data.productGroups.join(','));
        }

        if (data.refurbishedPhones && data.refurbishedPhones !== config.mobilePhonesConfig.defaultRefurbishedPhones) {
            queryString = WhistleOut.appendQueryParam(queryString, 'refurb=' + data.refurbishedPhones);
        }

        return queryString;
    }

    return wo$.param(data);
}

// TODO: Try reducing cyclomatic complexity and enable the eslint rule
// eslint-disable-next-line complexity
export function generatePhonesQueryString(data) {
    var queryString = '';
    if (data.minSpend && WhistleOut.isPositiveNumber(data.minSpend)) {
        queryString = WhistleOut.appendQueryParam(queryString, 'minspend=' + data.minSpend);
    }
    if (data.maxSpend &&
        WhistleOut.isPositiveNumber(data.maxSpend)) {
        queryString = WhistleOut.appendQueryParam(queryString, 'maxspend=' + data.maxSpend);
    }
    if (parseInt(data.maxUpfront) > -1) {
        queryString = WhistleOut.appendQueryParam(queryString, 'maxupfront=' + data.maxUpfront);
    }
    if (data.minData && parseInt(data.minData) !== 0) {
        queryString = WhistleOut.appendQueryParam(queryString, 'data=' + data.minData);
    }
    if (data.camera && WhistleOut.isPositiveNumber(data.camera) && data.camera >= data.cameraMinValue) {
        queryString = WhistleOut.appendQueryParam(queryString, 'camera=' + data.camera);
    }
    if (data.minScreen && WhistleOut.isPositiveNumber(data.minScreen) && data.minScreen >= data.screenMinValue) {
        queryString = WhistleOut.appendQueryParam(queryString, 'minscreen=' + data.minScreen);
    }
    if (data.maxScreen && WhistleOut.isPositiveNumber(data.maxScreen) && data.maxScreen < data.screenMaxValue) {
        queryString = WhistleOut.appendQueryParam(queryString, 'maxscreen=' + data.maxScreen);
    }
    if (data.suppliers.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'supplier=' + data.suppliers.join(','));
    if (data.networks.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'network=' + data.networks.join(','));
    if (data.brands.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'brand=' + data.brands.join(','));
    if (data.models && data.models.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'phone=' + data.models.join(','));
    if (data.operatingSystems.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'os=' + data.operatingSystems.join(','));
    if (data.priceTypes.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'pricetype=' + data.priceTypes.join(','));
    if (data.sortExpression && data.sortExpression !== 'popularity')
        queryString = WhistleOut.appendQueryParam(queryString, 'sort=' + data.sortExpression);
    if (data.sortAscending === false)
        queryString = WhistleOut.appendQueryParam(queryString, 'sortascending=false');
    if (data.showAll === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'showall=true');
    if (data.hideWidenResults === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'hidewidenresults=true');
    if (data.hideShare === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'hideshare=true');
    if (data.singlePhonePerBrand === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'singlePhonePerBrand=true');
    if (data.showEditorsPicksOnly === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'editorspicks=true');
    if (data.show5G === true)
        queryString = WhistleOut.appendQueryParam(queryString, '5g=true');
    if (data.showESim === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'esim=true');
    if (data.showTelstraBlueTickOnly === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'bluetick=true');
    if (data.optusRegionalPickOnly === true)
        queryString = WhistleOut.appendQueryParam(queryString, 'optuspick=true');
    if (data.showRowFilters === false)
        queryString = WhistleOut.appendQueryParam(queryString, 'showrowfilters=false');
    if (data.released && WhistleOut.isPositiveNumber(data.released))
        queryString = WhistleOut.appendQueryParam(queryString, 'released=' + data.released);
    if (data.planType) {
        queryString = WhistleOut.appendQueryParam(queryString, 'type=' + data.planType);
    }
    if (data.customer && data.customer.length > 0) {
        queryString = WhistleOut.appendQueryParam(queryString, 'customer=' + data.customer);
    }
    if (data.tab && data.tab !== data.defaultTab)
        queryString = WhistleOut.appendQueryParam(queryString, 'tab=' + data.tab);
    if (data.search && data.search.length > 0)
        queryString = WhistleOut.appendQueryParam(queryString, 'search=' + data.search);

    return queryString;

}

function _getLinesQueryString(lines) {
    const linesQueryStrings = [];
    wo$.each(lines, function (index, line) {
        const lineString = _getLineString(index, line);
        linesQueryStrings.push(lineString);
    });

    return linesQueryStrings;
}

function _getLineString(index, line) {
    var lineFields = [];
    lineFields.push(line.phone);
    lineFields.push(parseInt(line.calls) || 0);
    lineFields.push(parseInt(line.minutes) || 0);
    lineFields.push(parseInt(line.sms) || 0);
    lineFields.push(parseInt(line.data) || 0);
    lineFields.push(line.allowPhoneSub === true || line.allowPhoneSub === 1 ? 1 : 0);
    lineFields.push(line.tetherPhone === true || line.tetherPhone === 1 ? 1 : 0);
    if (line.phonePriceTypes && line.phonePriceTypes.length > 0) {
        lineFields.push(line.phonePriceTypes.map(function (priceType) { return priceType.value; })
            .join('-'));
    } else {
        lineFields.push('');
    }
    if (line.byoPhoneModelId && line.byoPhoneModelId > 0 && !line.phone) {
        lineFields.push(line.byoPhoneModelId);
    } else {
        lineFields.push('');
    }
    return lineFields.join('_');
}

export function getLinesFromString(value) {
    const lineStrings = (value || '').split(',');
    const lines = [];
    for (let i = 0; i < lineStrings.length; i++) {
        const lineString = lineStrings[i];
        const line = _getLineFromString(lineString);
        if (line) {
            lines.push(line);
        }
    }

    return lines;
}

function _getLineFromString(lineString) {
    if (!lineString || /^\s*$/.test(lineString)) {
        return {};
    }

    const values = lineString.split('_');
    const line = {
        phone: values[0],
        calls: values[1],
        minutes: values[2],
        sms: values[3],
        data: values[4],
        allowPhoneSub: values[5],
        tetherPhone: values[6],
        phonePriceTypes: _getPhonePriceTypes(values[7]),
        byoPhoneModelId: values[8]
    };

    return line;
}

function _getPhonePriceTypes(val) {
    if (!val || /^\s*$/.test(val)) {
        return null;
    }

    const values = val.split('-');
    if (!values || values.length === 0) {
        return null;
    }

    return values.filter(p => p).map(p => {
        return { value: p };
    });
}
